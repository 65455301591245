export const defaultSlideText: string = `
# MarkSlide

欢迎使用MarkSlide

## 功能简介

输入Markdown内容，可以自动渲染为幻灯片

`
